<template>
  <st-side-page class="financial-page">
    <portal to="page-name">Outros recursos</portal>
    <template slot="side">
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in menus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view/>
  </st-side-page>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { path: '/financial/settings', label: 'Configurações' },
        { path: '/financial/budgets', label: 'Orçamentos' },
        { path: '/financial/reports', label: 'Relatórios' },
        { path: '/providers', label: 'Fornecedores' },
        { path: '/financial/bank-accounts', label: 'Contas bancárias' },
        { path: '/financial/payment-methods', label: 'Formas de pagamento' },
        { path: '/financial/categories', label: 'Categorias' },
        { path: '/financial/cost-centers', label: 'Centros de custo' },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .financial-page {
    &.side-page {
      .side-page-side {
        flex: 0 0 11rem;
      }
    }
    .menu {
      .router-link-exact-active {
        color: $primary-color;
        & + svg {
          color: $primary-color;
        }
      }
    }
  }
</style>
